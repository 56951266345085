import React from 'react';
import forDirection from '../../hoc/for-direction';

export const MoreIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    role="img"
    {...props}
  >
    <path d="M17.444 6A1.5 1.5 0 1 1 19 4.5 1.528 1.528 0 0 1 17.444 6zm0 5A1.5 1.5 0 1 1 19 9.5a1.528 1.528 0 0 1-1.556 1.5zm0 5A1.5 1.5 0 1 1 19 14.5a1.528 1.528 0 0 1-1.556 1.5z" />
  </svg>
);

export const MoreIconRtl = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" role="img" {...props}>
    <path
      d="M17.444 6A1.5 1.5 0 1 1 19 4.5 1.528 1.528 0 0 1 17.444 6zm0 5A1.5 1.5 0 1 1 19 9.5a1.528 1.528 0 0 1-1.556 1.5zm0 5A1.5 1.5 0 1 1 19 14.5a1.528 1.528 0 0 1-1.556 1.5z"
      transform="translate(9.582843, 9.542641) scale(-1, 1) translate(-9.582843, -9.542641) "
    />
  </svg>
);

export default forDirection(MoreIcon, MoreIconRtl);
