import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './separator.scss';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';

export const A11yDashSeparator = withTranslate(({ className, t }) => (
  <span className={classNames(styles.a11y, className)}>{t('a11y-separator.dash')}</span>
));

export const HorizontalSeparator = ({ className, inverted, borderColor, isLarge }) => {
  return (
    <div
      className={classNames(styles.horizontal, className, getClassName(inverted), {
        [styles.large]: isLarge,
      })}
      style={{ backgroundColor: borderColor }}
    />
  );
};

HorizontalSeparator.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  isLarge: PropTypes.bool,
  borderColor: PropTypes.string,
};

export const VerticalSeparator = ({ className, inverted, borderColor }) => (
  <div
    className={classNames(styles.vertical, className, getClassName(inverted))}
    style={{ backgroundColor: borderColor }}
  />
);

VerticalSeparator.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  borderColor: PropTypes.string,
};

export const BulletSeparator = ({ spacing = 2, className }) => (
  <span
    aria-hidden
    className={className}
    style={{ fontWeight: 'bold' }}
    dangerouslySetInnerHTML={{
      __html: `${'&nbsp;'.repeat(spacing)}&centerdot;${'&nbsp;'.repeat(spacing)}`,
    }}
  />
);

BulletSeparator.propTypes = {
  spacing: PropTypes.number,
  className: PropTypes.string,
};

function getClassName(inverted) {
  return inverted
    ? 'forum-inverted-separator-background-color'
    : 'forum-separator-background-color';
}
