import PropTypes from 'prop-types';
import React from 'react';
import { ImgBackground, RESIZE_COVER } from '@wix/communities-image-lib';
import { getPostCover } from '../../../common/services/get-post-cover';
import PostLink from '../post-link';
import styles from './post-item-cover.scss';

export const PostItemCover = ({ post, width = 70, height = 70 }) => (
  <PostLink post={post}>
    <ImgBackground
      style={{ width: `${width}px`, height: `${height}px` }}
      className={styles.cover}
      src={getPostCover(post).src}
      fittingType={RESIZE_COVER}
      width={height}
      height={width}
    />
  </PostLink>
);

PostItemCover.propTypes = {
  post: PropTypes.object.isRequired,
  navigation: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
};

// prettier-ignore
export default PostItemCover;
