import React from 'react';
import { I18nextProvider } from 'react-i18next';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import { ImgProvider, RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY } from '@wix/communities-image-lib';
import RuntimeContext from '../../../common/components/runtime-context';
import RecentPostsWidget from '../widget';
import { PermissionsContextProvider } from '../../../common/components/permissions-context';
import { initI18n } from '../../../app/services/i18n-provider';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { getLanguage } from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { usePromisifiedAction } from '../../../common/actions-promisifier/use-promisified-action';

const permissionsResourceResolver = state => resource => {
  if (resource.categoryId) {
    resource = getCategory(state, resource.categoryId);
  }
  return resource;
};

class RecentPostsWidgetRoot extends React.Component {
  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { state, actions, actionsPromisified, host, isRTL, ...rest } = this.props;
    const i18n = this.getI18nConfig(getLanguage(state), getTranslations(state));

    const connectedActionsPromisified = Object.keys(actionsPromisified).reduce(
      (wrapped, actionName) => {
        wrapped[actionName] = usePromisifiedAction(actionsPromisified[actionName], state);
        return wrapped;
      },
      {},
    );

    return (
      <RuntimeContext.Provider
        value={{
          state,
          actions: { ...actions, ...connectedActionsPromisified },
          host: host || rest,
        }}
      >
        <PermissionsContextProvider state={state} resourceResolver={permissionsResourceResolver}>
          <DirectionProvider direction={isRTL ? DIRECTIONS.RTL : DIRECTIONS.LTR}>
            <I18nextProvider i18n={i18n}>
              <ImgProvider
                renderMode={RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY}
                format={isWebpSupported() ? 'webp' : 'png'}
              >
                <RecentPostsWidget />
              </ImgProvider>
            </I18nextProvider>
          </DirectionProvider>
        </PermissionsContextProvider>
      </RuntimeContext.Provider>
    );
  }
}

export default RecentPostsWidgetRoot;
