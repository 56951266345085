import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Highlighter from '../highlighter';
import Link from '../link/internal-link';
import BestAnswerIconLink from '../best-answer-icon-link';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import styles from './post-title.scss';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';

const renderTitle = (title, to, Link) => {
  return to ? (
    <Link to={to} className={classNames(styles.link, 'forum-text-color', 'forum-link-hover-color')}>
      {title}
    </Link>
  ) : (
    <span>{title}</span>
  );
};

class PostTitle extends Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
  }

  getLineCount() {
    if (this.titleRef.current) {
      const computedStyles = window.getComputedStyle(this.titleRef.current);
      const lineHeight = parseInt(computedStyles.lineHeight, 10);
      return Math.max(this.titleRef.current.offsetHeight / lineHeight, 1);
    }
  }

  componentDidMount() {
    this.props.setLineCount && this.props.setLineCount(this.getLineCount());
  }

  render() {
    const {
      title,
      type,
      query,
      className,
      to,
      component: Component,
      t,
      id,
      post,
      clamp,
      showBestAnswer,
    } = this.props;
    const containerClassName = classNames(styles.container, styles[type], className, 'post-title');
    const isQuestionType = post && post.postType === QUESTION;
    const clampStyle = clamp > 0 ? { WebkitLineClamp: clamp, MozLineClamp: clamp } : {};
    return (
      <Component id={id} className={containerClassName} data-hook="post-title">
        <div className={styles.title}>
          {showBestAnswer && isQuestionType && Boolean(post.bestAnswerCommentId) && (
            <div className={styles.bestAnswerIcon}>
              <BestAnswerIconLink
                t={t}
                commentId={post.bestAnswerCommentId}
                linkComponent={Link}
                toolTipClassName={styles.tooltip}
              />
            </div>
          )}
          <div
            ref={this.titleRef}
            className={classNames(styles.linkContainer, clamp > 0 ? styles.lineClamp : '')}
            style={clampStyle}
          >
            {renderTitle(query ? <Highlighter text={title} query={query} /> : title, to, Link)}
          </div>
        </div>
      </Component>
    );
  }
}

PostTitle.SMALL = 'small';
PostTitle.MEDIUM = 'medium';
PostTitle.LARGE = 'large';
PostTitle.SEARCH = 'search';
PostTitle.MEMBERS_AREA = 'membersArea';
PostTitle.FOOTER_POST = 'footerPost';
PostTitle.POST_PAGE = 'postPage';
PostTitle.RECENT_POSTS_WIDGET = 'recentPostsWidget';

PostTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  query: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  to: PropTypes.string,
  component: PropTypes.any,
  t: PropTypes.func,
  clamp: PropTypes.number,
  showBestAnswer: PropTypes.bool,
  setLineCount: PropTypes.func,
};

PostTitle.defaultProps = {
  component: 'h2',
  clamp: 0,
  showBestAnswer: true,
};

export default flowRight(withTranslate)(PostTitle);
