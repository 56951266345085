import React from 'react';
import classNames from 'classnames';

export const NotificationFollowingIcon = ({ fillClass, strokeClass, props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" {...props}>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0H24V24H0z" transform="translate(-4 -3)" />
      <path
        className={classNames('forum-icon-fill', fillClass)}
        fill-rule="nonzero"
        d="M12 4.105c.756 0 1.388.531 1.543 1.24 2.447.615 4.246 2.654 4.246 5.076v6.316c0 .581-.518 1.052-1.157 1.052l-2.053.001c-.244 1.201-1.306 2.105-2.579 2.105-1.273 0-2.335-.904-2.579-2.105H7.368c-.64 0-1.157-.472-1.157-1.053V10.42c0-2.421 1.798-4.46 4.247-5.074.154-.71.786-1.242 1.542-1.242z"
        transform="translate(-4 -3)"
      />
      <path
        fill-rule="nonzero"
        className={classNames('forum-icon-stroke', strokeClass)}
        stroke-linecap="round"
        d="M15 4c2.27.7 4 2.252 4 4M9 4c-2.27.7-4 2.252-4 4"
        transform="translate(-4 -3)"
      />
    </g>
  </svg>
);
