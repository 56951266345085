const { getImageFilename, getImageUrl } = require('./image');

const MAX_THUMBNAIL_WIDTH = 640;
const MAX_THUMBNAIL_HEIGHT = 480;

export const resizeThumbnail = imageHost => (pathname, width, height) => ({
  url: getImageUrl(imageHost)(
    {
      file_name: getImageFilename(pathname),
      width,
      height,
    },
    {
      maxWidth: MAX_THUMBNAIL_WIDTH,
      maxHeight: MAX_THUMBNAIL_HEIGHT,
    },
  ),
  width: MAX_THUMBNAIL_WIDTH,
  height: MAX_THUMBNAIL_HEIGHT,
});
