import { TRANSFORM_TYPE_FIT } from './constants';

const { min } = require('lodash');

const blurParam = blur => (blur > 0 ? `blur_${blur},` : '');

export const getImageFilename = pathname => (pathname ? pathname.replace(/^media\//, '') : '');

export function getImageUrl(imageHost) {
  return function produceImage(
    { file_name: fileName, width: actualWidth, height: actualHeight } = {},
    {
      maxWidth = 1000,
      maxHeight = 1000,
      quality = 80,
      type = TRANSFORM_TYPE_FIT,
      format = 'png',
      blur = 0,
    } = {},
  ) {
    if (fileName) {
      const [width, height] = [min([maxWidth, actualWidth]), min([maxHeight, actualHeight])];
      return `https://${imageHost}/media/${fileName}/v1/${type}/w_${width},h_${height},${blurParam(
        blur,
      )}al_c,q_${quality}/file.${format}`;
    }
    return '';
  };
}
