import React from 'react';

export const NotificationIcon = props => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.05000906,15 L5.1,15 C4.49248678,15 4,14.5522847 4,14 L4,8 C4,5.69998834 5.70827798,3.76269459 8.03431738,3.17949743 C8.18111314,2.50506611 8.78157432,2 9.5,2 C10.2184257,2 10.8188869,2.50506611 10.9656826,3.17949743 C13.291722,3.76269459 15,5.69998834 15,8 L15,14 C15,14.5522847 14.5075132,15 13.9,15 L11.9499909,15 C11.7183558,16.1411202 10.709479,17 9.5,17 C8.29052104,17 7.28164422,16.1411202 7.05000906,15 Z M10.9146471,15 L8.08535285,15 C8.29127106,15.5825962 8.84689059,16 9.5,16 C10.1531094,16 10.7087289,15.5825962 10.9146471,15 Z M9.5,4 C7.01471863,4 5,5.78979527 5,7.99761963 L5,14 L14,14 L14,7.99761963 C14,5.78979527 11.9852814,4 9.5,4 Z" />
  </svg>
);
